@use 'utils' as *;

[type='radio'],
[type='checkbox'] {
    appearance: none;
    border: 1px solid $color-main;
    cursor: pointer;
    display: inline-block;
    height: 1.29em;
    position: relative;
    width: 1.29em;

    &::after {
        content: '';
        height: 100%;
        inset: 0;
        opacity: 0;
        position: absolute;
        width: 100%;
    }

    &:disabled {
        filter: grayscale(1);
        opacity: 0.4;
    }

    &:focus-visible {
        background-color: $color-focus;
        outline: none;
    }

    &:checked::after {
        opacity: 1;
    }

    label & {
        margin-block-start: 0;
    }
}

[type='checkbox'] {
    border: 3px solid #141414;
    border-radius: 5px;
}

[type='checkbox']::after {
    background: linear-gradient(#141414, #141414) no-repeat 50% 50% / 0.7em 0.7em;
}

[type='radio'] {
    border-radius: 50%;

    &::after {
        background-clip: content-box;
        background-color: $color-main;
        border-radius: 50%;
        padding: 0.2em;
    }
}

[type='date'],
[type='email'],
[type='password'],
[type='text'],
select,
textarea {
    appearance: unset;
    border: unset;
    border: 1px solid transparent;
    padding: 1em 1.14em;
    position: relative;
    width: 100%;

    &:focus-visible {
        color: $color-focus;
        outline: dashed 1px $color-focus;
        outline-offset: -0.5em;
    }

    &:disabled,
    .disabled {
        opacity: 0.5;
        pointer-events: none;
    }

    &.error {
        color: $color-error;
    }
}

select {
    --background: #{rgba($color-main, 0.05)};

    background-color: var(--background);

    &:focus {
        --background: #{rgba($color-focus, 0.4)};

        outline-offset: 0.2em;
    }
}

[type='date'] {
    color-scheme: dark;
}
