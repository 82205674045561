@use 'utils' as *;

// Text Highlight Styles
::selection {
    background: rgba($color-main, 0.15);
    color: $color-base;
}

body {
    @include paragraph;
}

h1 {
    @include title-h1;
}

h2 {
    @include title-h2;
}

h3 {
    @include title-h3;
}

h4 {
    @include title-h4;
}

h5 {
    @include title-h5;
}

h6 {
    @include title-h6;
}

a {
    @include link;
}

a:not([class]) {
    text-decoration: underline;
}

b,
strong {
    font-weight: 700;
}

i,
em {
    font-style: italic;
}

u {
    text-decoration: underline;
}

button {
    @include button;
}

label {
    @include label;
}
