@use 'utils' as *;
@use 'reset' as *;
@use 'typography' as *;
@use 'forms' as *;

// Page Defaults
html,
body {
    @include layout-full-page;
}

html {
    --container-padding: 10rem;

    @media (max-width: $container-width-min) {
        --container-padding: 2rem;
    }
}

body {
    background: $color-base no-repeat 50% 0 / 100% auto;
    color: $color-main;
    height: auto;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}
